import httpClient from './httpClient';

export const IMPORT_ENDPOINT = '/backoffice/imports';

export const importProductInfoAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/product-info`,
		formData,
		params,
	);
};

export const importProductInfoDynamicFieldAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/product-info-dynamic-field`,
		formData,
		params,
	);
};

export const importProductPriceAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/product-price`,
		formData,
		params,
	);
};

export const importProductGroupAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/product-group`,
		formData,
		params,
	);
};

export const importProductShortAttributeAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/product-short-attribute`,
		formData,
		params,
	);
};

export const importInstallmentPlanAPI = (file, planId) => {
	const formData = new FormData();
	const params = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};
	formData.append('file', file);
	formData.append('installment_plan_id', planId);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/installment-plans`,
		formData,
		params,
	);
};

export const importPreOrderPickupAtStoreAPI = (file, ruleId) => {
	const formData = new FormData();
	const params = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};
	formData.append('file', file);
	formData.append('pre_order_pickup_rule_id', ruleId);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/pre-order-pickup-rule-sku`,
		formData,
		params,
	);
};

export const importProductAppleCareAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_ENDPOINT}/product-apple-care`,
		formData,
		params,
	);
};
