<template>
	<div>
		<h4>Available SKUs for pick up</h4>
		<div class="typo-body-2 color-black-45 mb-3">
			<span>
				Set available SKUs that can pick up by export and import spreadsheet.<br>Supported file type xls, xlsx.
			</span>
		</div>

		<table class="table-available-skus w-100 typo-body-2 mt-4">
			<thead>
				<tr>
					<th>Upload</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{{ quantity }} record(s)</td>
					<td class="text-right">
						<CButton
							class="btn-export transparent"
							@click="handleExport"
						>
							<CIcon
								class="mr-2"
								name="cil-arrow-thick-from-bottom"
							/> Export
						</CButton>
						<label
							:class="['btn-import transparent', { 'disabled': !isImportable }]"
						>
							<CIcon class="mr-2" name="cil-arrow-thick-from-top" /> Import
							<input
								ref="file-import"
								data-id="file-import"
								type="file"
								class="d-none"
								:disabled="!isImportable"
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
								@change="handleImport($event)"
							>
						</label>
					</td>
				</tr>
			</tbody>
		</table>

		<BaseModalLoading :ref="refModalLoading" :modal-title="modalTitle" />
	</div>
</template>

<script>
import { mapActions } from 'vuex';

import { importPreOrderPickupAtStoreAPI } from '../services/api/import.api';
import { exportPreOrderPickupAtStoreAPI } from '../services/api/export.api';

export default {
	name: 'PreOrderPickupAtStoreAvailableSKU',
	props: {
		quantity: {
			type: Number,
			default: 0,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
		isImportable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			refModalLoading: 'modal-loading',
			modalTitle: null,
			ruleId: this.$route.params.pickupId,
		};
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),
		async handleImport(event) {
			const file = event.target.files[0];

			this.modalTitle = 'Importing';
			this.$refs[this.refModalLoading].open();

			try {
				await importPreOrderPickupAtStoreAPI(file, this.ruleId);
				this.showToast({
					content: 'Available SKUs successfully uploaded. It could take up to 30 minutes for the result to take effect.',
					header: 'Success',
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					content: 'Available SKUs upload failed',
					header: 'Failure',
					type: 'danger',
				});
			} finally {
				this.$refs[this.refModalLoading].close();
				this.$refs['file-import'].value = '';
			}
		},
		async handleExport() {
			this.modalTitle = 'Exporting';
			this.$refs[this.refModalLoading].open();

			try {
				await exportPreOrderPickupAtStoreAPI(this.ruleId);
			} finally {
				this.$refs[this.refModalLoading].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.table-available-skus {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}
	}

	.btn-import,
	.btn-export {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}

		&.disabled {
			&:hover {
				color: $color-black-45;
				opacity: 0.4;
			}

			opacity: 0.4;
		}
	}
</style>
