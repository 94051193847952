<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="main-wrapper">
		<div class="mt-4 mb-4">
			<CInput
				v-model="$v.pickupRulesName.$model"
				:is-valid="!$v.pickupRulesName.$error && null"
				:invalid-feedback="nameErrorMessage"
				:readonly="isEdit"
				label="Pre-order pickup at stores rules (for back office)*"
				placeholder="E.g. iPhone pre-order campaign"
				data-test-id="pre-order-pickup-at-stores-name"
				@input="(value) => $emit('update:pickupRulesName', value)"
			/>
		</div>

		<!-- Branch -->
		<AvailableBranch
			:is-editable="!isEdit"
			:branch-ids="branchIds"
			title="Available branch for pickup"
			class="mt-4"
			@update:branchIds="handleBranchListChange"
		/>

		<div
			v-if="isEdit"
			class="py-4"
		>
			<hr class="mt-4 mb-2">
		</div>
		<!-- End Branch -->

		<!-- Available SKUs -->
		<PreOrderPickupAtStoreAvailableSKU
			v-if="isEdit"
			:is-editable="!isEdit"
			:is-importable="isImportable"
			:quantity="recordsCount"
		/>

		<div class="py-4">
			<hr class="mt-4 mb-5">
		</div>

		<BaseActionPanelStickyFooter
			:is-edit="isEdit"
			:disabled-confirm="isSubmitting || (isEdit && !isImportable)"
			:button-edit-confirm-text="isSubmitting ? 'Saving...' : 'Save changes'"
			:remove-text="isEdit && isRemovable ? 'Remove rule' : ''"
			content-class="main-wrapper"
			data-test-id="save-change-pickup-at-store"
			@onConfirm="handleSubmit"
			@onCancel="$router.push(routePreOrderEdit)"
			@onRemove="$refs['modal-remove'].open()"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePreOrderPickupAtStoreRule.bind(null, { preOrderId, preOrderPickupId })"
			title="Remove this pre-order pickup at stores rules?"
			description="By removing this, pre-order pickup at stores rules info will be disappeared from the list."
			@onSuccess="$router.push(routePreOrderEdit)"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import AvailableBranch from '@/components/AvailableBranch.vue';
import PreOrderPickupAtStoreAvailableSKU from '@/components/PreOrderPickupAtStoreAvailableSKU.vue';

import { ROUTE_NAME } from '../enums/route';
import { scrollToTop } from '../assets/js/helpers';
import { transformedPickupRule } from '../assets/js/transform/preorders';

export default {
	name: 'PreOrderPickupAtStoreForm',
	validations: {
		pickupRulesName: {
			required,
			maxLength: maxLength(255),
		},
	},
	components: {
		AvailableBranch,
		PreOrderPickupAtStoreAvailableSKU,
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		preOrderId: {
			type: [Number, String],
			default: null,
		},
		preOrderPickupId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			isLoading: true,
			isSubmitting: false,
			branches: [],
			routePreOrderEdit: {
				name: ROUTE_NAME.PRE_ORDER_EDIT,
				params: {
					id: this.preOrderId,
				},
			},
			pickupRulesName: null,
			recordsCount: 0,
			isImportable: true,
			isRemovable: true,
		};
	},
	computed: {
		...mapState('preorders', {
			pickupAtStore: 'pickupAtStore',
			edit: 'pickupAtStore/edit',
		}),
		...mapGetters({
			warehouseList: 'warehouses/warehouseList',
		}),
		branchIds() {
			return this.branches.map((item) => item.id);
		},
		nameErrorMessage() {
			if (!this.$v.pickupRulesName.required) {
				return 'Required Field';
			}

			if (!this.$v.pickupRulesName.maxLength) {
				return 'Maximum name length is 255';
			}

			return null;
		},
	},
	async created() {
		await this.getWarehouseList({ per_page: 'all' });

		if (this.isEdit) {
			await this.getPreOrderPickupRule({
				preOrderId: this.preOrderId,
				preOrderPickupId: this.preOrderPickupId,
			});
			this.extractData();
		} else {
			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getWarehouseList: 'warehouses/getWarehouseList',
			getPreOrderPickupRule: 'preorders/getPreOrderPickupRule',
			createPreOrderPickupRule: 'preorders/createPreOrderPickupRule',
			updatePreOrderPickupRule: 'preorders/updatePreOrderPickupRule',
			deletePreOrderPickupAtStoreRule: 'preorders/deletePreOrderPickupAtStoreRule',
		}),
		getBranchListFromIds(branchIds = []) {
			return this.warehouseList.filter((item) => branchIds.includes(item.id));
		},
		extractData() {
			const pickupRule = this.pickupAtStore.edit.data;

			const {
				name,
				branchIds,
				recordsCount,
				isImportable,
				isRemovable,
			} = transformedPickupRule(pickupRule) || {};

			this.pickupRulesName = name;
			this.branches = this.getBranchListFromIds(branchIds);
			this.recordsCount = recordsCount;
			this.isImportable = isImportable;
			this.isRemovable = isRemovable;
			this.isLoading = false;
		},
		handleBranchListChange(branchIds = []) {
			this.branches = this.getBranchListFromIds(branchIds);
		},
		checkFormValidation() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return false;
			}
			return true;
		},
		async handleSubmit() {
			const isFormValid = this.checkFormValidation();
			if (!isFormValid) {
				scrollToTop();
				return;
			}

			this.isSubmitting = true;

			const params = {
				name: this.pickupRulesName,
				branch_ids: this.branchIds,
			};

			try {
				if (!this.isEdit) {
					await this.createPreOrderPickupRule({
						id: this.preOrderId,
						params,
					});

					this.$router.push(this.routePreOrderEdit);
				} else {
					await this.updatePreOrderPickupRule({
						preOrderId: this.preOrderId,
						preOrderPickupId: this.preOrderPickupId,
						params,
					});
				}
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>
